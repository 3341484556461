<template>
  <div>
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug"/>
    <div class="content overflow-hidden">
      <validation-observer ref="VFormSubmit">
        <b-form @submit.prevent="doSubmit">
          <div class="card">
            <div class="card-header bg-white">
              <h6 class="card-title font-weight-semibold">Verifikasi Resume Medis</h6>
            </div>
            <div class="card-body">
              <div class="alert alert-info">Verifikasi pilihan ICD-10 dan ICD-9-CM</div>
              <div class="form-row">
                <div class="col-md-6">
                  <table class="table table-bordered table-sm">
                    <tr>
                      <td width="50%">
                        <div class="result_tab">
                          <h4>Tgl. Masuk</h4>
                          <p>{{rowReg.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}} WIB</p>
                        </div>
                      </td>
                      <td width="50%">
                        <div class="result_tab">
                          <h4>Tgl. Keluar</h4>
                          <p>{{rowReg.aranr_keluar_ranap_at | moment("DD MMMM YYYY, HH:mm")}} WIB</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <div class="result_tab">
                          <h4>Alasan Masuk RS</h4>
                          <p>{{rowResumeMedis.aranresm_hospitalization_reason||"-"}}</p>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Alergi</h6>
                    </div>
                    <div class="card-body p-3">
                      <div class="form-row" v-if="rowResumeMedis.aranresm_has_alergi == 'Y'">
                        <div class="col-md-12">
                            <div class="table-responsive">
                            <table class="table table-bordered">
                                <tbody>
                                    <tr v-for="(v,k) in (rowResumeMedis.aranresm_alergi||[])" :key="k+'ob'">
                                        <td>
                                            <div class="result_tab">
                                            {{v.jenis||"-"}}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="result_tab">
                                            {{v.name||"-"}}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                      </div>
                      <div class="form-row" v-else>
                          <div class="col-md-12">
                              <span>Tidak Ada Alergi</span>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Tanda-Tanda Vital</h6>
                </div>
                <div class="card-body p-3">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Tekanan Darah</h4>
                              <p v-if="rowResumeMedis.aranresm_sistolik && rowResumeMedis.aranresm_diastolik">{{rowResumeMedis.aranresm_sistolik||"-"}}/
                              {{rowResumeMedis.aranresm_diastolik}} mmHG</p>
                              <p v-else> - </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Nadi</h4>
                              <p v-if="rowResumeMedis.aranresm_nadi">{{rowResumeMedis.aranresm_nadi}}x/mnt <span v-if="rowResumeMedis.aranresm_nadi_type">- {{rowResumeMedis.aranresm_nadi_type||"-"}}</span>
                              </p>
                              <p v-else> - </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Gula Darah</h4>
                              <p v-if="rowResumeMedis.aranresm_gda">{{rowResumeMedis.aranresm_gda}}mg/dL</p>
                              <p v-else> - </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Pernafasan</h4>
                              <p v-if="rowResumeMedis.aranresm_pernafasan">{{rowResumeMedis.aranresm_pernafasan}}x/mnt</p>
                              <p v-else> - </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Suhu</h4>
                              <p v-if="rowResumeMedis.aranresm_suhu">{{rowResumeMedis.aranresm_suhu}} &deg;C</p>
                              <p v-else> - </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Kesadaran</h4>
                              <template v-if="tingkatKesadaranTotal">
                                  <div class="">
                                  <span class="font-weight-semibold">Nilai GCS: {{tingkatKesadaranTotal}}</span>
                                  <span v-if="tingkatKesadaranTotal >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                                  <span v-else-if="tingkatKesadaranTotal >= 12 && tingkatKesadaranTotal <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                  <span v-else-if="tingkatKesadaranTotal >= 10 && tingkatKesadaranTotal <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                  <span v-else-if="tingkatKesadaranTotal >= 7 && tingkatKesadaranTotal <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                                  <span v-else-if="tingkatKesadaranTotal >= 5 && tingkatKesadaranTotal <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                  <span v-else-if="tingkatKesadaranTotal >= 4 && tingkatKesadaranTotal <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                  <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                  </div>
                                  
                                  <div class="">
                                      <span class="font-weight-semibold mr-3">E : {{rowResumeMedis.aranresm_gcs_response_mata||"-"}}</span>
                                      <span class="font-weight-semibold mr-3">M : {{rowResumeMedis.aranresm_gcs_response_motorik||"-"}}</span>
                                      <span class="font-weight-semibold mr-3">V : {{rowResumeMedis.aranresm_gcs_response_verbal||"-"}}</span>
                                  </div>
                              </template>
                              <p v-else> - </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Keadaan Umum</h6>
                </div>
                <div class="card-body p-3">
                  <table class="table table-bordered">
                    <tr>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Kepala</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_kepala == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_kepala == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_kepala_text||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Mata</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_mata == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_mata == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_mata_text||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Hidung</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_hidung == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_hidung == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_hidung_text||"-"}}</p>
                        </div>
                    </td>
                    </tr>
                    <tr>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Rambut</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_rambut == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_rambut == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_rambut_text||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Bibir</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_bibir == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_bibir == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_bibir_text||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Gigi Geligi</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_gigi_geligi == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_gigi_geligi == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_gigi_geligi_text||"-"}}</p>
                        </div>
                    </td>
                    </tr>
                    <tr>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Lidah</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_lidah == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_lidah == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_lidah_text||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Langit-Langit</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_langit_langit == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_langit_langit == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_langit_langit_text||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Leher</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_leher == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_leher == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_leher_text||"-"}}</p>
                        </div>
                    </td>
                    </tr>
                    <tr>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Tenggorokan</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_tenggorokan == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_tenggorokan == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_tenggorokan_text||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Tonsil</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_tonsil == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_tonsil == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_tonsil_text||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Dada</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_dada == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_dada == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_dada_text||"-"}}</p>
                        </div>
                    </td>
                    </tr>
                    <tr>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Payudara</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_payudara == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_payudara == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_payudara_text||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Punggung</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_punggung == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_punggung == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_punggung_text||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Perut</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_perut == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_perut == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_perut_text||"-"}}</p>
                        </div>
                    </td>
                    </tr>
                    <tr>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Genital</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_genital == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_genital == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_genital_text||"-"}}</p>
                        </div>
                    </td>
                    
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Anus</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_anus == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_anus == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_anus_text||"-"}}</p>
                        </div>
                    </td>
                    
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Lengan Atas</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_lengan_atas == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_lengan_atas == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_lengan_atas_text||"-"}}</p>
                        </div>
                    </td>
                    </tr>
                    <tr>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Lengan Bawah</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_lengan_bawah == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_lengan_bawah == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_lengan_bawah_text||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Jari Tangan</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_jari_tangan == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_jari_tangan == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_jari_tangan_text||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Kuku Tangan</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_kuku_tangan == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_kuku_tangan == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_kuku_tangan_text||"-"}}</p>
                        </div>
                    </td>
                    </tr>
                    <tr>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Sendi Tangan</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_persendian_tangan == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_persendian_tangan == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_persendian_tangan_text||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Tungkai Atas</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_tungkai_atas == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_tungkai_atas == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_tungkai_atas_text||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Tungkai Bawah</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_tungkai_bawah == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_tungkai_bawah == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_tungkai_bawah_text||"-"}}</p>
                        </div>
                    </td>
                    </tr>
                    <tr>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Jari Kaki</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_jari_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_jari_kaki == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_jari_kaki_text||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Kuku Kaki</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_kuku_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_kuku_kaki == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_kuku_kaki_text||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Sendi Kaki</h4>
                        <p v-if="rowResumeMedis.aranresm_ku_persendian_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                        <p v-else-if="rowResumeMedis.aranresm_ku_persendian_kaki == 'TP'">Tidak Diperiksa</p>
                        <p v-else>{{rowResumeMedis.aranresm_ku_persendian_kaki_text||"-"}}</p>
                        </div>
                    </td>
                    </tr>
                    <tr>
                      <td colspan="3">
                        <div class="result_tab">
                          <h4>Saraf</h4>
                          <p v-if="rowResumeMedis.aranresm_ku_saraf == 'N'">TAK (Tidak Ada Kelainan)</p>
                          <p v-else-if="rowResumeMedis.aranresm_ku_saraf == 'TP'">Tidak Diperiksa</p>
                          <p v-else> - </p>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              
              <div class="card-body">
                  <div class="alert alert-info">Verifikasi pilihan ICD-10 dan ICD-9-CM</div>
                  
                  <div class="card">
                  <div class="card-header bg_head_panel shadow-0 border">
                      <span class="card-title font-weight-semibold mb-0">Verifikasi ICD</span>
                  </div>

                  <div class="card-group mb-2">
                      <div class="card border shadow-0">
                      <div class="bg-light card-header">
                          <span class="card-title mb-0 font-weight-semibold">Diagnosis Dokter</span>
                      </div>
                      <div class="card-body py-2" v-if="(dataICD10Dok||[]).length">
                          <div class="req-list">
                          <h6 class="text-uppercase">ICD-10-CM dipilih:</h6>
                          <div v-for="(v,k) in (dataICD10Dok||[])" :key="k">
                              <template v-if="v.mi10_name">
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle" data-popup="tooltip"
                                  title="Klik untuk menjadikan Diagnosis Primer">{{v.mi10_name}} <span
                                  class="font-weight-semibold">[{{v.mi10_code}}]</span></span>
                              </template>
                          </div>
                          </div>
                      </div>
                      <div class="card-body py-2" v-else>
                          <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-10-CM dipilih</label>
                      </div>

                      <div class="card-body">
                          <div class="req-list">
                          <h6 class="text-uppercase mb-0">Diagnosa Lainnya</h6>
                          <div v-if="(dataICD10DokLainnya||[]).length">
                            <div class="req-list">
                              <div v-for="(v,k) in (dataICD10DokLainnya||[])" :key="k">
                                  <template v-if="v.text">
                                    <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                    <span class="align-middle" data-popup="tooltip"
                                        title="Klik untuk menjadikan Diagnosis Primer">{{v.text}}</span>
                                  </template>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <label for="" class="font-weight-semibold mb-0">Tidak ada diagnosa lainnya</label>
                          </div>
                        </div>
                      </div>
                      </div>
                      <div class="card border shadow-0">
                      <div class="bg-light card-header">
                          <span class="card-title mb-0 font-weight-semibold">Verifikasi Diagnosis</span>
                      </div>
                      <div class="card-body">
                          <div class="form-group mb-0">
                          <div class="input-group">
                              <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                              <v-select class="diagnosa-section select-paging" placeholder="Pilih Diagnosa"
                                  v-model="inputICD10" @search="filterICD10($event,0)" @input="selectICD10(inputICD10)"
                                  :options="mICD10" label="text" :clearable="true" :reduce="v=>v.value">

                                  <template slot="selected-option">
                                  <span v-b-tooltip.hover.right :title="inputICD10Name">
                                      {{inputICD10Name||"-"}}
                                  </span>
                                  </template>

                                  <template slot="option" slot-scope="option">
                                  <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                  </template>
                                  <template #list-footer v-if="(mICD10||[]).length >= 1">
                                  <li class="list-paging">
                                      <div class="d-flex align-items-center justify-content-between">
                                      <b-button @click="changePageICD10(mICD10[0].currentPage,0,'min','mICD10')"
                                          size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                          class="icon-arrow-left22 text-white"></i></b-button>
                                      <small>Halaman {{mICD10[0].currentPage}} dari
                                          {{ceilData(mICD10[0].totalRows/5)}}</small>
                                      <b-button @click="changePageICD10(mICD10[0].currentPage,0,'plus','mICD10')"
                                          size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                          class="icon-arrow-right22 text-white"></i></b-button>
                                      </div>
                                  </li>
                                  </template>
                              </v-select>
                              </div>
                          </div>
                          </div>
                      </div>
                      <table class="table table-bordered table-sm" v-if="(dataICD10||[]).length">
                          <thead>
                          <tr>
                              <th width="64">Primer</th>
                              <th>Item ICD-10 dipilih</th>
                              <th width="64">Aksi</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(v,k) in (dataICD10||[])" :key="k">
                              <td>
                              <template>
                                  <div class="custom-control custom-radio custom-control-inline">
                                  <input v-model="dataICD10Primer" :value="v.aranicd10r_id" type="radio"
                                      name="primaryDiagnose10" class="custom-control-input"
                                      :id="`primaryDiagnose10${k}`" /><label class="custom-control-label"
                                      :for="`primaryDiagnose10${k}`"></label></div>

                                  <VValidate :name="'Diagnosa Primer'" v-model="dataICD10Primer" :rules="{required:1}" />
                              </template>
                              </td>
                              <td><span>{{v.mi10_name||"-"}}<span
                                  class="font-weight-semibold">[{{v.mi10_code||"-"}}]</span></span></td>
                              <td>
                              <a href="javascript:;" @click="removedataICD10(v.aranicd10r_id, k)"
                                  class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
                                  <i class="icon-trash-alt"></i>
                              </a>
                              </td>
                          </tr>
                          </tbody>
                      </table>
                      <div class="card-body py-2" v-else>
                          <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-10-CM dipilih</label>
                      </div>
                      </div>
                  </div>

                  <div class="card-group mt-5">
                      <div class="card border shadow-0">
                      <div class="bg-light card-header">
                          <h6 class="card-title mb-0 font-weight-semibold">Tindakan Dokter</h6>
                      </div>
                      <div class="card-body py-2" v-if="(dataICD9Dok||[]).length">
                          <div class="req-list">
                          <h6 class="text-uppercase">ICD-9-CM dipilih:</h6>
                          <div v-for="(v,k) in (dataICD9Dok||[])" :key="k">
                              <template v-if="v.mi9_name">
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle" data-popup="tooltip"
                                  title="Klik untuk menjadikan Diagnosis Primer">{{v.mi9_name}} <span
                                  class="font-weight-semibold">[{{v.mi9_code}}]</span></span>
                              
                                <a href="javascript:;" @click="openInformConsentDetail(v)" v-if="v.aranci9d_informed_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                                <i class="icon-file-eye2"></i>
                                </a>
                              </template>
                          </div>
                          </div>
                      </div>
                      <div class="card-body py-2" v-else>
                          <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-9-CM dipilih</label>
                      </div>

                      <div class="card-body">
                          <div class="req-list">
                          <h6 class="text-uppercase mb-0">Tindakan Lainnya</h6>
                          <div v-if="(dataICD9DokLainnya||[]).length">
                            <div class="req-list">
                              <div v-for="(v,k) in (dataICD9DokLainnya||[])" :key="k">
                                  <template v-if="v.text">
                                    <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                    <span class="align-middle" data-popup="tooltip"
                                        title="Klik untuk menjadikan Diagnosis Primer">{{v.text}}</span>
                                  </template>
                              </div>
                              </div>
                          </div>
                          <div v-else>
                            <label for="" class="font-weight-semibold mb-0">Tidak ada diagnosa lainnya</label>
                          </div>
                          </div>
                      </div>
                      </div>
                      <div class="card border shadow-0">
                      <div class="bg-light card-header">
                          <h6 class="card-title mb-0 font-weight-semibold">Verifikasi Tindakan</h6>
                      </div>
                      <div class="card-body">
                          <div class="form-group mb-0">
                          <div class="input-group">
                              <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                              <v-select class="diagnosa-section select-paging" placeholder="Pilih Tindakan"
                                  v-model="inputICD9" @search="filterICD9($event,0)" @input="selectICD9(inputICD9)"
                                  :options="mICD9" label="text" :clearable="true" :reduce="v=>v.value">

                                  <template slot="selected-option">
                                  <span v-b-tooltip.hover.right :title="inputICD9Name">
                                      {{inputICD9Name||"-"}}
                                  </span>
                                  </template>

                                  <template slot="option" slot-scope="option">
                                  <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                  </template>
                                  <template #list-footer v-if="(mICD9||[]).length >= 1">
                                  <li class="list-paging">
                                      <div class="d-flex align-items-center justify-content-between">
                                      <b-button @click="changePageICD9(mICD9[0].currentPage,0,'min','mICD9')" size="sm"
                                          variant="transparent" class="btn-icon bg-blue-300"><i
                                          class="icon-arrow-left22 text-white"></i></b-button>
                                      <small>Halaman {{mICD9[0].currentPage}} dari
                                          {{ceilData(mICD9[0].totalRows/5)}}</small>
                                      <b-button @click="changePageICD9(mICD9[0].currentPage,0,'plus','mICD9')" size="sm"
                                          variant="transparent" class="btn-icon bg-blue-300"><i
                                          class="icon-arrow-right22 text-white"></i></b-button>
                                      </div>
                                  </li>
                                  </template>
                              </v-select>
                              </div>
                          </div>
                          </div>
                      </div>
                      <table class="table table-bordered table-sm" v-if="(dataICD9||[]).length">
                          <thead>
                          <tr>
                              <th>Item ICD-9 dipilih</th>
                              <th width="64">Aksi</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(v,k) in (dataICD9||[])" :key="k">
                              <td><span>{{v.mi9_name||"-"}}<span
                                  class="font-weight-semibold">[{{v.mi9_code||"-"}}]</span></span></td>
                              <td>
                              <a href="javascript:;" @click="removedataICD9(v.aranicd9r_id, k)"
                                  class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
                                  <i class="icon-trash-alt"></i>
                              </a>
                              </td>
                          </tr>
                          </tbody>
                      </table>
                      <div class="card-body py-2" v-else>
                          <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-9-CM dipilih</label>
                      </div>
                      </div>
                  </div>

                  </div>
              </div>

              <table class="table table-sm table-bordered">
                <tr>
                  <td class="align-top" width="50%">
                    <div class="result_tab">
                      <h4>Pemeriksaan Penunjang</h4>
                      <table class="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <td></td>
                                <th>Hasil Pemeriksaan</th>
                                <th>Hasil</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-for="(v,k) in tindakan.lab">
                                <tr v-for="(dtHasil, keyHasil) in v.arantl_hasil" :key="`lkh-${k}-${keyHasil}`" :id="k">
                                  <td :rowspan="v.arantl_hasil.length == 1 ? 1 : v.arantl_hasil.length" v-if="keyHasil == 0">
                                    <label>
                                      Laboratorium

                                      <ul class="pl-3">
                                        <li v-for="(dtTindakan, keyTindakan) in v.arantl_tindakan.split(', ')" :key="`atin-${keyTindakan}`">
                                          {{ dtTindakan }}
                                        </li>
                                      </ul>
                                    </label>
                                  </td>
                                  <td>
                                    {{ dtHasil.value }}
                                  </td>
                                  <td>
                                    <a :href="uploader(dtHasil.file)" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal" v-if="dtHasil.file">
                                      <i class="icon-file-eye2"></i>
                                    </a>
                                  </td>
                                </tr>
                              </template>
                              
                              <tr v-if="!(tindakan.lab||[]).length">
                                <td>
                                  <label>
                                    Laboratorium
                                  </label>
                                </td>
                                <td>
                                  <span>Tidak ada pemeriksaan</span>
                                </td>
                                <td>
                                </td>
                              </tr>

                              <template v-for="(v,k) in tindakan.radiologi">
                                <tr v-for="(dtHasil, keyHasil) in v.arantr_hasil" :key="`rkh-${k}-${keyHasil}`" :id="k">
                                  <td :rowspan="v.arantr_hasil.length == 1 ? 1 : v.arantr_hasil.length" v-if="keyHasil == 0">
                                    <label>
                                      Radiologi

                                      <ul class="pl-3">
                                        <li v-for="(dtTindakan, keyTindakan) in v.arantr_tindakan.split(', ')" :key="`ratin-${keyTindakan}`">
                                          {{ dtTindakan }}
                                        </li>
                                      </ul>
                                    </label>
                                  </td>
                                  <td>
                                    {{ dtHasil.value }}
                                  </td>
                                  <td>
                                    <a :href="uploader(dtHasil.file)" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal" v-if="dtHasil.file">
                                      <i class="icon-file-eye2"></i>
                                    </a>
                                  </td>
                                </tr>
                              </template>
                              <tr v-if="!(tindakan.radiologi||[]).length">
                                <td>
                                  <label>
                                    Radiologi
                                  </label>
                                </td>
                                <td>
                                  <span>Tidak ada pemeriksaan</span>
                                </td>
                                <td>
                                </td>
                              </tr>
                              
                              <tr v-for="(v, k) in tindakan.fisio" :key="`tf-${k}`">
                                <td>
                                  <label>
                                    Fisioterapi
                                  </label>

                                  <ul class="pl-3">
                                    <li v-for="(dtTindakan, keyTindakan) in [...(v.arantf_elektroterapi||[]), ...(v.arantf_mekanoterapi||[]), ...(v.arantf_latihan||[])]" :key="`fatin-${keyTindakan}`">
                                      <span v-if="dtTindakan == 'LE'">
                                      {{v.arantf_elektroterapi_text||"-"}}</span>
                                      <span v-else-if="dtTindakan == 'LM'">
                                      {{v.arantf_mekanoterapi_text||"-"}}</span>
                                      <span v-else-if="dtTindakan == 'LL'">
                                      {{v.arantf_latihan_text||"-"}}</span>
                                      <span v-else>{{ dtTindakan }}</span>
                                    </li>
                                  </ul>
                                </td>
                                <td>
                                  <span>Tidak ada pemeriksaan</span>
                                </td>
                                <td></td>
                              </tr>
                              <tr v-if="!(tindakan.fisio||[]).length">
                                <td>
                                  <label>
                                    Fisioterapi
                                  </label>
                                </td>
                                <td>
                                  <span>Tidak ada pemeriksaan</span>
                                </td>
                                <td>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <label>
                                    Lain-lain
                                  </label>
                                </td>
                                <td>-</td>
                                <td>-</td>
                              </tr>
                            </tbody>
                          </table>

                    </div>
                  </td>
                  <td class="align-top" width="50%">
                    <div class="result_tab">
                      <h4>Konsultasi/Pengobatan</h4>
                      <p>{{rowResumeMedis.aranresm_consultation||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="result_tab">
                      <h4>Keadaan Keluar</h4>
                      <p>{{rowResumeMedis.aranresm_keadaan_keluar||"-"}}</p>
                    </div>
                  </td>
                  <td class="align-top">
                    <div class="result_tab">
                      <h4>Cara Keluar</h4>
                      <p>{{rowResumeMedis.aranresm_cara_keluar||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="result_tab">
                      <h4>Tindak Lanjut</h4>
                      <p>{{rowResumeMedis.aranresm_tindak_lanjut||"-"}}</p>
                    </div>
                  </td>
                  <td class="align-top">
                    <div class="result_tab">
                      <h4>Anjuran/Edukasi</h4>
                      <p>{{rowResumeMedis.aranresm_reccomendation||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div class="card-footer">
              <div class="text-right">
                <button @click="$router.back()" type="button" class="btn btn-transparent ml-auto mr-2">Back</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
              </div>
            </div>
            <!-- /dashboard content -->
          </div>
        </b-form>
      </validation-observer>
    </div>

    
    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
        <table class="table table-bordered table-sm table-input">
        <thead>
            <tr>
            <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan / ICD-9-CM</h4>
                    <p>{{rowIC.mi9_name}} <span class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
                </div>
            </td>
            </tr>
            <tr>
            <th width="24">No.</th>
            <th>Jenis Informasi</th>
            <th>Isi Informasi</th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td>1</td>
            <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
            <td>
            {{rowIC.auic_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>2</td>
            <td>Dasar Diagnosis</td>
            <td>
            {{rowIC.auic_dasar_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>3</td>
            <td>Tindakan Kedokteran</td>
            <td>
            {{rowIC.auic_tindakan_dokter||"-"}}
            </td>
            </tr>
            <tr>
            <td>4</td>
            <td>Indikasi Tindakan</td>
            <td>
            {{rowIC.auic_indikasi_tindakan||"-"}}
            </td>
            </tr>
            <tr>
            <td>5</td>
            <td>Tata Cara</td>
            <td>
            {{rowIC.auic_tata_cara||"-"}}
            </td>
            </tr>
            <tr>
            <td>6</td>
            <td>Tujuan</td>
            <td>
            {{rowIC.auic_tujuan||"-"}}
            </td>
            </tr>
            <tr>
            <td>7</td>
            <td>Risiko</td>
            <td>
            {{rowIC.auic_risiko||"-"}}
            </td>
            </tr>
            <tr>
            <td>8</td>
            <td>Komplikasi</td>
            <td>
            {{rowIC.auic_kompilasi||"-"}}
            </td>
            </tr>
            <tr>
            <td>9</td>
            <td>Prognosis</td>
            <td>
            {{rowIC.auic_prognosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>10</td>
            <td>Alternatif & Risiko</td>
            <td>
            {{rowIC.auic_alternatif||"-"}}
            </td>
            </tr>
            <tr>
            <td>11</td>
            <td>Lain-lain</td>
            <td>
            {{rowIC.auic_lainnya||"-"}}
            </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
            <td width="50%" colspan="2">
                <div class="result_tab">
                <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                <p v-if="rowIC.auic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                <p v-else>Tindakan ditolak</p>
                </div>
            </td>
            <td width="50%">
                <div class="result_tab">
                <h4>Persetujuan tindakan diberikan kepada</h4>
                <p v-if="rowIC.auic_persetujuan_tindakan == 'P'">Pasien</p>
                <p v-else>Penanggung Jawab</p>
                </div>
            </td>
            </tr>
        </tfoot>
        </table>
    </b-modal>
  </div>
</template>

<script>
import PatientInfo from '@/components/Ranap/PatientInfo.vue'
import Gen from '@/libs/Gen'
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._

export default {
  extends: GlobalVue,
  components: {
    PatientInfo
  },
  data() {
    return {
      loading: {
        patientInfo: false
      },
      patientData: {},
      rowReg: {},
      rowResumeMedis: {},
      tindakan: [],

      dataICD10Primer: null,
      dataICD9: [],
      dataICD9Dok: [],
      dataICD9DokLainnya: [],
      dataICD10: [],
      dataICD10Dok: [],
      dataICD10DokLainnya: [],

      mICD9: [],
      mICD10: [],
      
      inputICD9: null,
      inputICD10: null,
      inputICD9Name: null,
      inputICD10Name: null,
      searchICD10Name: null,
      searchICD9Name: null,

      openModalICDetail: false,
      rowIC: {}

    }
  },
  computed: {
    tingkatKesadaranTotal(){
      let total = ((this.rowResumeMedis.aranresm_gcs_response_mata||0) + (this.rowResumeMedis.aranresm_gcs_response_verbal||0) + (this.rowResumeMedis.aranresm_gcs_response_motorik||0))
      return total
    },
  },
  methods: {
    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.params.pageSlug
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)
        this.$set(this.loading, 'patientInfo', false)
      })
    },
    
    apiGetMaster() {
      let slugs = (this.pageSlug ? "/" + this.pageSlug : "") + (this.pageId ? "/" + this.pageId : "")

      Gen.apiRest(
        "/get/" + this.modulePage +
        slugs + `?master=1`, {}
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
      })
    },
  
    filterICD10: _.debounce(function (e, k) {
      let id = []
      for (let i = 0; i < (this.dataICD10 || []).length; i++) {
        if (this.dataICD10[i]['aranicd10r_icd_10']) {
          id.push(this.dataICD10[i]['aranicd10r_icd_10'])
        }
      }

      this.searchICD10Name = e

      Gen.apiRest('/do/' + 'RanapRekamMedis', {
        data: {
          type: 'select-icd-10',
          search: e,
          id: id
        }
      }, 'POST').then(res => {
        this.mICD10 = res.data.data
      })
    }, 10),


    changePageICD10(page, k, act, col) {
      if (act == 'min') {
        if (page > 1) {
          page -= 1
          this[col][k]['currentPage'] -= 1
        }
      } else {
        if (page < this.ceilData(this[col][k]['totalRows'] / 5)) {
          page += 1
          this[col][k]['currentPage'] += 1
        }
      }

      let data = {
        type: 'select-paging-icd-10',
        page: page,
        search:  this.searchICD10Name
      }

      Gen.apiRest(
        "/do/" + 'RanapRekamMedis', {
          data: data
        },
        "POST"
      ).then(res => {
        this.mICD10 = res.data.data
        this.getmICD10(this.dataICD10, k)
      })
    },

    filterICD9: _.debounce(function (e, k) {
      let id = []
      for (let i = 0; i < (this.dataICD9 || []).length; i++) {
        if (this.dataICD9[i]['aranicd9r_icd_9']) {
          id.push(this.dataICD9[i]['aranicd9r_icd_9'])
        }
      }
      
      this.searchICD9Name = e
      
      Gen.apiRest('/do/' + 'RanapRekamMedis', {
        data: {
          type: 'select-icd-9',
          search: e,
          id: id
        }
      }, 'POST').then(res => {
        this.mICD9 = res.data.data
      })
    }, 10),

    changePageICD9(page, k, act, col) {
      if (act == 'min') {
        if (page > 1) {
          page -= 1
          this[col][k]['currentPage'] -= 1
        }
      } else {
        if (page < this.ceilData(this[col][k]['totalRows'] / 5)) {
          page += 1
          this[col][k]['currentPage'] += 1
        }
      }
      let data = {
        type: 'select-paging-icd-9',
        page: page,
        search:  this.searchICD9Name
      }
      Gen.apiRest(
        "/do/" + 'RanapRekamMedis', {
          data: data
        },
        "POST"
      ).then(res => {
        this.mICD9 = res.data.data
        this.getmICD9(this.dataICD9, k)
      })
    },


    getmICD9(diagnosa, k) {
      const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
        return k2 != k
      }), v2 => {
        return v2.aranicd9r_icd_9
      })
      return _.filter(this.mICD9, v2 => {
        return sldMenu.indexOf(v2.value) <= -1
      })
    },

    getmICD10(diagnosa, k) {
      const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
        return k2 != k
      }), v2 => {
        return v2.aranicd10r_icd_10
      })
      return _.filter(this.mICD10, v2 => {
        return sldMenu.indexOf(v2.value) <= -1
      })
    },

    selectICD9(e) {
      let index = this.mICD9.findIndex(x => x.value == e)
      if (index !== -1) {
        this.inputICD9Name = this.mICD9[index]['text']
        this.searchICD9Name = null
      } else {
        this.inputICD9Name = null
      }
      this.addNewICD9()
    },
    
    selectICD10(e) {
      let index = this.mICD10.findIndex(x => x.value == e)
      if (index !== -1) {
        this.inputICD10Name = this.mICD10[index]['text']
        this.searchICD10Name = null        
      } else {
        this.inputICD10Name = null
      }
      this.addNewICD10()
    },

    openInformConsentDetail(v){
        Gen.apiRest('/do/' + 'RanapCPPTDokter', {
            data: {
              type: 'get-data-ic',
              id: v.aranci9d_informed_consent,
            }
        }, 'POST').then(res => {
            this.rowIC = res.data.row 
            this.rowIC.mi9_name = v.mi9_name
            this.rowIC.mi9_code = v.mi9_code
            this.rowIC.id_icd = v.aranci9d_id
            this.openModalICDetail = true                   
        })
    },

    ceilData(value) {
      return Math.ceil(value)
    },


    
    addNewICD9() {
      if (this.inputICD9) {
        let data = {
          aranicd9r_arancp_id: this.row.arancp_id,
          aranicd9r_aranr_id: this.rowReg.aranr_id,
          aranicd9r_icd_9: this.inputICD9,
          type: 'add-icd-9-rm'
        }
        Gen.apiRest(
          "/do/" + 'RanapRekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          let resp = res.data
          this.dataICD9.push(resp.row)
          this.inputICD9 = null
        })
      }
    },

    removedataICD9(id, k) {
      let data = {
        id: id,
        type: 'remove-icd-9-rm'
      }
      Gen.apiRest(
        "/do/" + 'RanapRekamMedis', {
          data: data
        },
        "POST"
      ).then(() => {
        this.dataICD9.splice(k, 1)
      })
    },

    addNewICD10() {
      if (this.inputICD10) {
        let data = {
          aranicd10r_arancp_id: this.row.arancp_id,
          aranicd10r_aranr_id: this.rowReg.aranr_id,
          aranicd10r_icd_10: this.inputICD10,
          type: 'add-icd-10-rm'
        }
        Gen.apiRest(
          "/do/" + 'RanapRekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          let resp = res.data
          this.dataICD10.push(resp.row)
          this.inputICD10 = null
        })
      }
    },

    removedataICD10(id, k) {
      let data = {
        id: id,
        type: 'remove-icd-10-rm'
      }
      Gen.apiRest(
        "/do/" + 'RanapRekamMedis', {
          data: data
        },
        "POST"
      ).then(() => {
        this.dataICD10.splice(k, 1)
      })
    },

    autoSaveICD9: _.debounce(function (data) {
      let dataPost = {
        type: 'auto-save-icd-9-rm',
        data: data
      }
      if (!this.row.isEdit) {
        Gen.apiRest(
          "/do/" + 'RanapRekamMedis', {
            data: dataPost
          },
          "POST"
        )
      }
    }, 1000),

    autoSaveICD10: _.debounce(function (data) {
      let dataPost = {
        type: 'auto-save-icd-10-rm',
        data: data
      }
      if (!this.row.isEdit) {
        Gen.apiRest(
          "/do/" + 'RanapRekamMedis', {
            data: dataPost
          },
          "POST"
        )
      }
    }, 1000),
  
    doSubmit() {
      this.$refs['VFormSubmit'].validate().then(success => {
        if (!success) {
          return this.$swal({
            icon: 'error',
            title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
            if (result.value) {
              setTimeout(() => {
                let inv = []
                let el = document.querySelectorAll('.label_error')
                for (let i = 0; i < (el || []).length; i++) {
                  if (el[i].style.display !== 'none') {
                    inv.push(el[i].id)
                  }
                }
                if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                  behavior: 'smooth',
                  block: 'center'
                })
              }, 500)
            }
          })
        }
        
        if(!(this.dataICD10||[]).length){
          return  this.$swal({
            icon: 'warning',
            title: 'Tidak ada ada ICD 10',
          })
        }

        if (success) {
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.rowReg
              data.dataICD9 = this.dataICD9

              for (let i = 0; i < (this.dataICD10 || []).length; i++) {
                this.dataICD10[i]['aranicd10r_is_primary'] = 'N'
              }
              data.dataICD10 = this.dataICD10
              if ((data.dataICD10 || []).length) {
                let indexPrimary2 = this.dataICD10.findIndex(x => x.aranicd10r_id == this.dataICD10Primer)
                data.dataICD10[indexPrimary2]['aranicd10r_is_primary'] = 'Y'
              }

              data.type = 'submit-rm'
              Gen.apiRest(
                "/do/" + 'RanapRekamMedis', {
                  data: data
                },
                "POST"
              ).then(res => {
                this.loadingOverlay = false
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                  title: resp.message,
                  icon: resp.status,
                  confirmButtonText: 'Ok'
                }).then(result => {
                  if (result.value) {
                    this.$router.push({
                      name: 'RanapRekamMedis'
                    }).catch(() => {})
                  }
                })
              }).catch(err => {
                this.loadingOverlay = false
                if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
            }
          })
        }
      })
    },

  },
  mounted() {
    this.getPatientInfo()
    this.apiGet()
    this.apiGetMaster()
  },
  watch: {
    $route() {
      this.apiGet()
      this.apiGetMaster()
    },
    dataICD9: {
      handler(v) {
        this.autoSaveICD9(v)
      },
      deep: true
    },
    dataICD10: {
      handler(v) {
        this.autoSaveICD10(v)
      },
      deep: true
    },
  }
}

</script>