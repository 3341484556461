<template>
  <div>
    <div class="table-responsive">
      <table class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
        <thead>
          <tr>
            <th>No.</th>
            <th>Nama Dokumen</th>
            <th>Status</th>
            <th width="130">Aksi</th>
          </tr>
          <tr v-for="(v,k) in (allField||[])" :key="k">
            <td>{{k+1}}</td>
            <td>{{v.text}}</td>
            <td>
              <div v-if="!rowDoc[v.field]" class="badge badge-warning mb-1">Belum Diisi</div>
              <div v-else class="badge badge-success mb-1">Sudah Diupload</div>
            </td>
            <td>
              <a href="javascript:;" @click="toUpload(v.field)" class="btn btn-icon btn-sm rounded-round alpha-info border-info text-info-800 mb-1" v-b-tooltip.hover.right title="Upload Dokumen">
                <i class="icon-upload"></i>
              </a>
              <a :href="uploader(rowDoc[v.field])" target="_blank" v-if="rowDoc[v.field]" class="btn btn-icon btn-sm rounded-round alpha-success border-success text-success-800 mb-1" v-b-tooltip.hover.right title="Unduh Dokumen">
                <i class="icon-download"></i>
              </a>
            </td>
          </tr>
          <tr>
            <th colspan="4">
              Dokumen Rawat Inap Lainnya
            </th>
          </tr>
          <tr v-for="(v,k) in (rowDoc.arandrm_dokumen_lainnya||[])" :key="k+'ss'">
            <td>{{k+16}}</td>
            <td>
              <b-form-input type="text" class="form-control" v-model="rowDoc.arandrm_dokumen_lainnya[k]['nama']" placeholder="Nama Dokumen"/>

              <VValidate 
                :name="'Nama Dokumen #'+(k+16)" 
                v-model="rowDoc.arandrm_dokumen_lainnya[k]['nama']" 
                :rules="{required: 1, min:2, max: 128}"
              />
            </td>
            <td>
              <div v-if="!rowDoc.arandrm_dokumen_lainnya[k]['file']" class="badge badge-warning mb-1">Belum Diisi</div>
              <div v-else class="badge badge-success mb-1">Sudah Diupload</div>
            </td>
            <td>
              <a href="javascript:;" @click="doUploadLainnya(v,k)" class="btn btn-icon btn-sm rounded-round alpha-info border-info text-info-800 mb-1" v-b-tooltip.hover.right title="Upload Dokumen">
                <i class="icon-upload"></i>
              </a>

              <a :href="uploader(rowDoc.arandrm_dokumen_lainnya[k]['file'])" target="_blank" v-if="rowDoc.arandrm_dokumen_lainnya[k]['file']" class="btn btn-icon btn-sm rounded-round alpha-success border-success text-success-800 mb-1" v-b-tooltip.hover.right title="Unduh Dokumen">
                <i class="icon-download"></i>
              </a>

              <a @click="rowDoc.arandrm_dokumen_lainnya.splice(k,1)" href="javascript:;" class="btn btn-icon btn-sm rounded-round alpha-danger border-danger text-danger-800 mb-1" v-b-tooltip.hover.right title="Hapus">
                <i class="icon-bin"></i>
              </a>


            </td>
          </tr>
          <tr>
            <td colspan="4" class="text-center">
              <button type="button" @click="addDokRanap" class="btn btn-primary">Tambah Dokumen</button>
            </td>
          </tr>
        </thead>
      </table>
    </div>

    <validation-observer ref="VFormDokumen">    
    <b-modal v-model="openUpload" :title="'Upload Dokumen'" size="sm" @ok.prevent="uploadDokumen">
      <div class="file-uploader">
        <Uploader isDocument v-model="rowDoc[field]" type="docimage"/>
        <VValidate 
          name="Dokumen" 
          v-model="rowDoc[field]" 
          :rules="{required: 1}"
        />
      </div>
    </b-modal>
    </validation-observer>

    
    <template v-if="(rowDoc.arandrm_dokumen_lainnya||[]).length && keyData != null">
      <validation-observer ref="VFormDokumenLainnya">    
      <b-modal v-model="openUploadLainnya" :title="'Upload Dokumen Lainnya'" size="sm" @ok.prevent="uploadDokumenLainnya">
        <div class="file-uploader">
          <Uploader isDocument v-model="rowDoc.arandrm_dokumen_lainnya[keyData]['file']" type="docimage"/>
          <VValidate 
            name="Dokumen" 
            v-model="rowDoc.arandrm_dokumen_lainnya[keyData]['file']" 
            :rules="{required: 1}"
          />
        </div>
      </b-modal>
      </validation-observer>
    </template>
  </div>
</template>


<script>

import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'

export default{
  extends: GlobalVue,
  computed: {
    isParent(){
      return this.$parent.$parent.$parent
    },
  },
  
  data() {
    return {
      rowDoc: {},
      rowUpload: {},
      openUpload: false,
      field: null,
      allField: [
        { field: 'arandrm_surat_keterangan_kepolisian', text: 'SURAT KETERANGAN KEPOLISIAN / SURAT PENOLAKAN' },
        { field: 'arandrm_surat_jasa_raharja', text: 'SURAT JASA RAHARJA' },
        { field: 'arandrm_hasil_pemeriksaan_lab_radio', text: 'SEMUA HASIL PEMERIKSAAN LAB & RADIO YANG DIKIRIM KELUAR (REKANAN)' },
        { field: 'arandrm_hasil_ecg', text: 'HASIL ECG' },
        { field: 'arandrm_hasil_echo', text: 'HASIL ECHO' },
        { field: 'arandrm_hasil_spirometri', text: 'HASIL SPIROMETRI' },
        { field: 'arandrm_tbc_stib', text: 'KASUS TBC HARUS ADA SITB' },
        { field: 'arandrm_efusi_pleura', text: 'KASUS EFFUSI PLEURA' },
        { field: 'arandrm_advis_alat_bantu', text: 'ADVIS ALAT BANTU' },
        { field: 'arandrm_neonatus', text: 'LEMBAR PELAYANAN KESEHATAN NEONATUS' },
        { field: 'arandrm_paktograf', text: 'PAKTOGRAF' },
        { field: 'arandrm_kartu_bpjs_bayi', text: 'NOMOR KARTU BPJS BAYI' },
        { field: 'arandrm_streptokinase', text: 'BUKTI PEMAKAIAN STREPTOKINASE, ALBUMIN, OCTABULIN' },
        { field: 'arandrm_penggunaan_alat_khusus', text: 'BUKTI PENGGUNAAN ALAT KHUSUS HIP DAN KNEE (SPESIAL PROSEDUR / PAKET ARTHROPLAST)' },
        { field: 'arandrm_billing_pasien', text: 'BILLING PASIEN' }
      ],
      keyData: null,
      openUploadLainnya: false
    }
  },

  methods: {
    doUploadLainnya(row,k){
      if(!row.nama){
        return this.$swal({
          icon: 'error',
          title: 'Nama Dokumen Belum Diisi',
        })
      }
      this.keyData = k
      this.openUploadLainnya = true
    },
    apiGetDoc(){
      this.loadingOverlay = true
      let data = {
        type : "get-dokumen-klaim",
        regId : this.isParent.rowEdit.aranr_id
      }
      Gen.apiRest(
      "/do/" + this.modulePage, {
        data: data
      },
      "POST"
      ).then(res => {
        this.loadingOverlay = false
        this.rowDoc = res.data.rowDoc
				this.rowDoc.arandrm_dokumen_lainnya = this.rowDoc.arandrm_dokumen_lainnya || []
      })
    },
    toUpload(fieldUpload){
      this.openUpload = true
      this.field = fieldUpload
    },
    addDokRanap(){
      this.rowDoc.arandrm_dokumen_lainnya.push({
        nama : null,
        file : null,
      })
    },
    uploadDokumenLainnya(){
      this.$refs['VFormDokumen'].validate().then(success=>{
        if(success){
          let data = this.rowDoc
          data.type = "upload-dokumen-kelengkapan"
          
          this.loadingOverlay = true
          Gen.apiRest(
          "/do/" + this.modulePage, {
            data: data
          },
          "POST"
          ).then(res => {
            this.loadingOverlay = false
            this.openUploadLainnya = false
            this.$swal({
              title: "Dokumen Kelengkapan Berhasil Diupload",
              icon: "success",
            })
          })
        }
      })
    },
    uploadDokumen(){
      this.$refs['VFormDokumen'].validate().then(success=>{
        if(success){
          let data = this.rowDoc
          data.type = "upload-dokumen-kelengkapan"
          
          this.loadingOverlay = true
          Gen.apiRest(
          "/do/" + this.modulePage, {
            data: data
          },
          "POST"
          ).then(res => {
            this.loadingOverlay = false
            this.openUpload = false
            this.$swal({
              title: "Dokumen Kelengkapan Berhasil Diupload",
              icon: "success",
            })
          })
        }
      })
    }
  },
  
  mounted() {
    this.apiGetDoc()  
  },
  watch:{
    'openUpload'(v){
      if(!v){
        this.apiGetDoc()  
      }
    }
  }
  
}
</script>