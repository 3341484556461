<template>
  <div class="content-wrapper">
    <div v-if="isList" class="content pt-0">
        <bo-page-title />
        <div class="card">
            <div class="card-header bg-white">
                <div class="row align-items-center">
                    <div class="col-md-5">
                        <div class="form-row align-items-center">
                            <div class="col-md-4">
                                <h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
                            </div>
                            <div class="col-md-8">
                                <div class="form-group mb-0">
                                        <div class="input-group">
                                            <date-range-picker
                                                ref="picker"
                                                :locale-data="datePickerConfig.locale"
                                                :autoApply="datePickerConfig.autoApply"
                                                v-model="dateRange"
                                                :opens="'right'"
                                                :ranges="datePickerConfig.ranges"
                                                @update="updateValues"
                                            >
                                                <template v-slot:input="picker">
                                                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                                </template>
                                            </date-range-picker>
                                            <div class="input-group-append calendar-group">
                                                <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="card-header">
                <div class="row">
                    <div class="col-lg-9">
                        <div class="row gx-1">
                            <div class="col-md">
                                <div class="row gx-1">
                                    <div class="col-md-auto">
                                        <div class="form-group mb-0">
                                            <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right ><i class="icon-spinner11"></i></button>
                                        </div>
                                    </div>                                                                  

                                    <div class="col-md-3">
                                        <b-form-group class="mb-0">
                                            <v-select placeholder=" -- Pilih Jaminan -- " @input="doFill" v-model="filter.status_jaminan"  :options="Config.mr.statusJaminan" label="text" :reduce="v=>v.value"></v-select>
                                        </b-form-group>
                                    </div>

                                    <div class="col-md-3">
                                        <b-form-group class="mb-0">
                                            <v-select placeholder=" -- Pilih Cara Masuk -- " @input="doFill" v-model="filter.cara_masuk"  :options="Config.mr.caraMasuk" label="text" :reduce="v=>v.value"></v-select>
                                        </b-form-group>
                                    </div>
                                                
                                    <div class="col-md-6 col-lg-4">
                                        <b-form-group class="mb-0">
                                            <v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.status_ranap"  :options="Config.mr.statusRanapVerifRM" label="text" :reduce="v=>v.value" :clearable="true"></v-select>
                                        </b-form-group>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                            <input class="form-control"
                                v-model="filter.search"
                                @input="doSearch"
                                placeholder="Ketik Nama/No. RM Pasien"
                            />
                            <div class="form-control-feedback">
                                <i class="icon-search4 text-indigo"></i>
                            </div>
                                
                            <b-button
                                class="ml-1 d-inline-flex align-items-center"
                                variant="outline-success"
                                id="resetBtn"
                                @click="doResetData()"
                            >Reset</b-button>
                        </div>
                    </div>
                </div>
            </div>     
            
            <div class="table-responsive">
                <table
                    class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
                    <thead>
                        <tr>
                            <th>NO.</th>
                            <th>NO. KEDATANGAN</th>
                            <th>NAMA/NO KTP</th>
                            <th>AKSI</th>
                            <th>STATUS</th>
                            <th>RUANGAN</th>
                            <th>TANGGAL DAFTAR</th>
                            <th>NO RM</th>
                            <th>NO SEP</th>
                            <th>DOKTER DPJP</th>
                            <th>JENIS RAWAT INAP</th>
                           
                            <th>CARA BAYAR</th>
                            <th>TGL LAHIR</th>
                            <th>USIA</th>
                            <th>JENIS KELAMIN</th>   
                            <th>NAMA VERIFIKATOR</th>         
                           
                            <!--
                            <th>TANGGAL LAHIR</th>    
                            <th>USIA</th>    
                            <th>PPJP</th>
                            -->
                        </tr>
                    </thead>
                    
                    <tbody v-if="(dataList||[]).length">
                        <template v-for="(v,k) in (dataList||[])">
                            <tr :key="k">
                                <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                                <td>
                                    <div class="text-success-700 font-weight-semibold">{{v.aranr_reg_code||"-"}}</div>
                                </td>
                                <td>
                                    <div>
                                    <a href="javascript:;" @click="toCPPT(v)" class="font-weight-semibold border-bottom" v-b-tooltip.hover title="Lihat Data CPPT"> {{v.ap_fullname||"-"}}</a>
                                    <br>
                                    {{v.ap_code||"-"}}</div>
                                    <small class="text-secondary">Terdaftar {{v.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
                                </td>
                                <td>
                                    <a href="javascript:;" @click="toForm(v)" class="btn btn-icon btn-sm rounded-round alpha-info border-info text-info-800 mb-1" v-b-tooltip.hover.right title="Lihat Detail">
                                            <i class="icon-eye"></i>
                                    </a>
                                    
                                    <a href="javascript:;" @click="toKelengkapanDokumen(v)" class="btn btn-icon btn-sm rounded-round alpha-success border-success text-success-800 mb-1" v-b-tooltip.hover.right title="Kelengkapan Dokumen Klaim">
                                            <i class="icon-stack-text"></i>
                                    </a>

                                    <a href="javascript:;" @click="downloadResumeMedisDokter(v)" class="btn btn-icon rounded-round btn-sm alpha-warning border-warning text-warning-800 mb-1" v-b-tooltip.hover title="Download Resume Medis Dokter"><i class="icon-download"></i></a>

                                    <a href="javascript:;" @click="downloadResumeMedis(v)" v-if="v.aranr_is_verifed_resume_medis == 'Y'" class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800" v-b-tooltip.hover title="Download Resume Medis Dokter Verifikator"><i class="icon-download"></i></a>

                                    
                                </td>
                                <td>
                                  <div v-if="!v.aranr_ppjp && !v.aranr_perawat_pelaksana" class="badge badge-warning mb-1">Tugaskan PPJP</div>
                                  <div v-else-if="v.aranr_ppjp && !v.aranr_perawat_pelaksana" class="badge badge-warning mb-1">Tugaskan Perawat Pelaksana</div>
                                  <template v-else>
                                      <div :class="`badge ${v.arans_color} mb-1`">{{v.arans_name||"-"}}</div>
                                  
                                      <template v-if="v.aranr_status == 1">
                                          <div v-if="v.aranr_is_done_perawat != 'Y'" class="badge badge-warning mb-1">Pengkajian Awal Perawat</div>
                                          
                                          <div v-if="v.aranr_is_done_dokter != 'Y'" class="badge badge-warning mb-1">Pengkajian Awal Dokter</div>
                                      </template>
                                      
                                  </template>
                                </td>
                                <td>
                                    <div class="font-weight-semibold">{{v.mrank_name||"-"}}</div>
                                    <small><span>{{v.mranb_name||"-"}}</span> - <span>{{v.mrankel_name||"-"}}</span></small>
                                </td>
                                <td>
                                    <div>{{v.aranr_reg_date | moment("DD MMMM YYYY HH:mm")}} WIB</div>
                                </td>
                                <td>
                                    <div class="font-weight-semibold">{{v.ap_code||"-"}}</div>
                                    <span>dari {{v.aranres_cara_masuk == 'RAJAL'?'POLI': v.aranres_cara_masuk == 'BBL' ? 'BBL':'UGD'}}</span>
                                    <div class="text-success-700 font-weight-semibold">
                                        {{v.no_reg||"-"}}                                    
                                    </div>
                                </td>
                                <td>{{v.no_sep||"-"}}</td>
                                <td>{{v.bu_full_name||"-"}}</td>
                                <td>
                                    <div class="font-weight-semibold">{{getConfigDynamic(Config.mr.jenisRawatInap,v.aranres_jenis_ranap)||"-"}}</div>
                                </td>
                                <td>{{v.cara_pembayaran||"-"}}</td>
                                <td>{{v.ap_dob | moment("DD MMMM YYYY")}}</td>
                                <td>{{v.ap_usia_with_ket||"-"}}</td>
                                <td>
                                  <span v-if="v.ap_gender">{{v.ap_gender == 1 ? "Laki-Laki":"Perempuan"}}</span>
                                  <span v-else> - </span>
                                </td>
                                <td>{{v.verif_rm_by||"-"}}</td>
                            </tr>
                        </template>
                    </tbody>
                    <tbody v-if="data.data.length == 0">
                        <tr>
                            <td colspan="99">
                            <div class="text-center">
                                <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                                <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                            </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-if="!data.data">   
                        <tr>
                            <td colspan="99"><div class="skeletal-comp"></div></td>
                        </tr>
                        <tr>
                            <td colspan="99"><div class="skeletal-comp"></div></td>
                        </tr>
                        <tr>
                            <td colspan="99"><div class="skeletal-comp"></div></td>
                        </tr>
                    </tbody>
                </table>

                
                <div :class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
                    <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                    <i class="icon-chevron-left"></i>
                    </a>
                    <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                    <i class="icon-chevron-right"></i>
                    </a>
                </div>
            </div>
            
            <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">     <b-pagination
                class="mb-0"
                v-model="pageNo"
                :per-page="data.per_page"
                :total-rows="data.total"
            />
            </b-card-footer>


            <b-modal v-model="openKelengkapanDokumen" :title="'Kelengkapan Dokumen Klaim Rawat Inap'" size="lg" ok-only>
                <KelengkapanDokumen />
            </b-modal>

        </div>
    </div>
    <template v-else>
      <Form />
    </template>
  </div>
</template>


<script>
  import GlobalVue from '@/libs/Global.vue'
  import DateRangePicker from 'vue2-daterange-picker'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  import Form from './Form.vue'

  const moment = require('moment')
  
  // import Datepicker from 'vuejs-datepicker'
  const _ = global._
  import $ from 'jquery'
  import Gen from '@/libs/Gen.js'
  import KelengkapanDokumen from './KelengkapanDokumen.vue'
  
  export default {
    extends: GlobalVue,
    components:{
      DateRangePicker, Form, KelengkapanDokumen
    },
    data(){
      return {
        idKey:'aranr_id',
        datePickerConfig: {
            startDate: new Date(moment().subtract(6, 'days')),
            endDate: new Date(),
            autoApply: true,
            ranges: {
                'Hari Ini': [new Date(), new Date()],
                '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
            },
            applyClass: 'btn-sm btn-primary',
            cancelClass: 'btn-sm btn-light',
            locale: {
                applyLabel: 'Terapkan',
                cancelLabel: 'Batal',
                direction: 'ltr',
                format: 'mm/dd/yyyy',
                separator: ' - ',
            }
        },
        dateRange: {
            startDate: new Date(moment().subtract(6, 'days')),
            endDate: new Date(),
        },
        rowEdit : {},
        openKelengkapanDokumen: false
      }
    },
    methods: {
      toCPPT(row){
        this.$router.push({ name: 'RanapCPPT', params: {pageSlug: row.aranr_id}}).catch(()=>{})
      },
      setSlide(){
          const responsiveTable = document.querySelector('.table-responsive')
          if(responsiveTable){
              const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
              const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
              if(responsiveTableScroll){
                  // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                  Array.from(responsiveTableScrollActions).forEach(action => {
                      action.addEventListener('click', e => {
                          const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                            
                          responsiveTable.scroll({
                              left: scrollAmount,
                              behavior: 'smooth'
                          })
                      })
                  })
              }
          }
      },
      doResetData(){
          this.doReset()
          this.dateRange =  {
              startDate: new Date(moment().subtract(6, 'days')),
              endDate: new Date(),
          }
          this.doConvertDate()
      },
      doRefreshData(){
          this.apiGet()
          window.scrollTo(0,0)
      },
      doConvertDate(){
          this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
          this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
      },
      doPageOne(){
          this.filter.page = 1
      },
      doFill(){
          this.doPageOne()
          this.doFilter()
      },
      updateValues(e){
          this.doConvertDate()
          this.doFill()
      },
      doSearch: _.debounce(function(){
          this.doPageOne()
          this.doFilter()
      },500),
      getConfigDynamic(master,value){
          let text = ''
          if(value){
              let index = (master||[]).findIndex(x => x.value == value)
              if(index !== -1){
                  text = master[index]['text']
              }
          }
          return text
      },
      toKelengkapanDokumen(v){
          this.rowEdit = v
          this.openKelengkapanDokumen = true 
      },
      toForm(v){
        this.loadingOverlay=true
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:{type:'duplicate-icd', id: v.aranr_id}}, 
            "POST"
        ).then(()=>{
            this.loadingOverlay = false
            this.$router.push({ name: 'RanapRekamMedis', params: { pageSlug: v.aranr_id } }).catch(()=>{})
        })
      },
			downloadResumeMedis(data){
				this.$swal.fire({
					title: 'Processing...',
					showConfirmButton: false,
					allowOutsideClick: false,
					didOpen: () => {
						this.$swal.showLoading()
					}
				})

				Gen.apiRest(
					"/do/" + 'RanapResumeMedis', {
						data: {
							...data,
							type: "download-resume-medis",
							id_registrasi: data.aranr_id
						}
					},
					"POST"
				)
				.then(res => {
					const linkSource = res.data
					const downloadLink = document.createElement("a")
					downloadLink.href = linkSource
					downloadLink.download = `Resume-Medis-${new Date().getTime()}.pdf`
					downloadLink.click()

					this.$swal.close()
				})
				.catch(()=>{
					this.$swal.close()
					// this.globalVar.apiGenError( err.response.data )
				})
			},
      downloadResumeMedisDokter(data){
        this.$swal.fire({
            title: 'Processing...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
                this.$swal.showLoading()
            }
        })

        Gen.apiRest(
            "/do/" + 'RanapResumeMedis', {
                data: {
                    ...data,
                    type: "download-resume-medis-dokter",
                    id_registrasi: data.aranr_id
                }
            },
            "POST"
        )
        .then(res => {
            const linkSource = res.data
            const downloadLink = document.createElement("a")
            downloadLink.href = linkSource
            downloadLink.download = `Resume-Medis-Dokter-${new Date().getTime()}.pdf`
            downloadLink.click()

            this.$swal.close()
        })
        .catch(()=>{
            this.$swal.close()
            // this.globalVar.apiGenError( err.response.data )
        })
      },
    },
    filters: {
      date(val) {
          return val ? moment(val).format("D MMM YYYY") : ""
      }
    },
    mounted(){
      this.filter.status_jaminan = 'ALL'
      this.filter.cara_masuk = 'ALL'
      this.filter.status_ranap = 4
      
      this.doConvertDate()      
      this.apiGet()
    
      setTimeout(()=>{
          this.setSlide()
      },1000)
    },
    watch:{
      $route(){
        this.apiGet()
        
        setTimeout(()=>{
            if(this.isList){
                this.setSlide()
            }
        },1000)
      },
      '$route.params.pageSlug':function(){
        this.$set(this,'filter',{})
      }
    }
  }
</script>